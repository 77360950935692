import { VehicleInsuranceActionTypes, VehicleInsuranceActions } from './action';

import { VehicleInsurance } from '../../../models/insurances/VehicleInsurance';

const initialState: { payload: VehicleInsurance } = {
  payload: {
    insuranceType: 'KFZ-Versicherung',
    nameOfPolicyHolder: null,
    firstNameOfContactPerson: null,
    phoneOfContactPerson: null,
    lastNameOfContactPerson: null,
    opponentCarDamaged: null,
    opponentOfficialLicensePlate: null,
    opponentManufacturerAndType: null,
    opponentAgeOfVehicle: null,
    opponentEstimatedAmountOfDamage: null,
    opponentPreviousDamageCondition: null,
    claimantCompany: null,
    claimantFirstName: null,
    claimantLastName: null,
    claimantStreet: null,
    claimantNumber: null,
    claimantPc: null,
    claimantLoc: null,
    claimantPhone: null,
    claimantDamagedParts: null,
    damageDescription: null,
    photosTaken: null,
    damageFiles: null,
    claimsJustified: null,
    claimsJustifiedReasoning: null,
    policeProtocolMade: null,
    policeProtocolCarbonCopy: null,
    policeStation: null,
    fileReference: null,
    alcoholTestDone: null,
    alcoholTestResult: null,
    dayOfDamage: null,
    damageStreet: null,
    damageNumber: null,
    damagePostCode: null,
    damageLocation: null,
    constructionSite: null,
    licensePlate: null,
    driverFirstName: null,
    driverLastName: null,
    driverDrivingLicenseClass: null,
    driverDrivingLicenseIssueDate: null,
    driverDrivingLicensePlaceOfIssue: null,
    caseOfDamage: null,
    accidentOpponentClaims: null,
    insurer: null,
    vehicleInspectionCompany: null,
    vehicleInspectionFirstName: null,
    vehicleInspectionLastName: null,
    vehicleInspectionStreet: null,
    vehicleInspectionNumber: null,
    vehicleInspectionPc: null,
    vehicleInspectionLoc: null,
    damagedParts: null,
    damageType: null,
    witnessesAvailable: null,
    witnessFirstName: null,
    witnessLastName: null,
    witnessStreet: null,
    witnessNumber: null,
    witnessPc: null,
    witnessLoc: null,
    witnessPhone: null,
    personalInjuries: null,
    paymentTo: null,
    iban: null,
    email: null,
    additionalMailAddresses: null,
  },
};

export const vehicleReducer = (
  state: { payload: VehicleInsurance } = initialState,
  action: VehicleInsuranceActions
) => {
  switch (action.type) {
    case VehicleInsuranceActionTypes.GET_VALUE:
      return {
        ...state,
        payload: action.payload,
      };

    case VehicleInsuranceActionTypes.INIT_VALUE:
      return {
        ...initialState,
      };

    default:
      return { ...state };
  }
};
